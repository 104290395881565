import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ISupplier} from 'app/blocks/model/supplier.model';

export interface ISupplierTransaction extends IBaseEntity {
    id?: number;
    transactionDate: any;
    supplierTransactionType: string;
    supplier: ISupplier;
    purchaseInvoiceId?: number;
    purchaseInvoiceNo?: string;
    purchaseInvoicePaymentStatus?: string;
    purchaseCreditNoteId?: number;
    purchaseCreditNoteNo?: string;
    purchaseCreditNoteReference?: string;
    purchasePaymentId?: number;
    purchasePaymentReference?: string;
    startingBalance: number;
    amount: number;
    endingBalance: number;
}

export class SupplierTransaction extends BaseEntity implements ISupplierTransaction {
    public id: number;
    public transactionDate: any;
    public supplierTransactionType: string;
    public supplier: ISupplier;
    public purchaseInvoiceId: number;
    public purchaseInvoiceNo: string;
    public purchaseInvoicePaymentStatus: string;
    public purchaseCreditNoteId: number;
    public purchaseCreditNoteNo: string;
    public purchaseCreditNoteReference: string;
    public purchasePaymentId: number;
    public purchasePaymentReference: string;
    public startingBalance: number;
    public amount: number;
    public endingBalance: number;

    constructor(supplierTransaction?) {
        super();
        if (supplierTransaction) {
            this.id = supplierTransaction.id;
            this.transactionDate = supplierTransaction.transactionDate;
            this.supplierTransactionType = supplierTransaction.supplierTransactionType;
            this.supplier = supplierTransaction.supplier;
            this.purchaseInvoiceId = supplierTransaction.purchaseInvoiceId;
            this.purchaseInvoiceNo = supplierTransaction.purchaseInvoiceNo;
            this.purchaseInvoicePaymentStatus = supplierTransaction.purchaseInvoicePaymentStatus;
            this.purchaseCreditNoteId = supplierTransaction.purchaseCreditNoteId;
            this.purchaseCreditNoteNo = supplierTransaction.purchaseCreditNoteNo;
            this.purchaseCreditNoteReference = supplierTransaction.purchaseCreditNoteReference;
            this.purchasePaymentId = supplierTransaction.purchasePaymentId;
            this.purchasePaymentReference = supplierTransaction.purchasePaymentReference;
            this.startingBalance = supplierTransaction.previousBalance;
            this.amount = supplierTransaction.transactionAmount;
            this.endingBalance = supplierTransaction.newBalance;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): SupplierTransaction {
        return new SupplierTransaction(this);
    }
}
